<template>
  <div class="user-list-item" @click.prevent="$emit('selected', user)">
    <div class="left">
      <presence-thumbnail :presence-id="user.id" :owner-id="org.id" size="large"></presence-thumbnail>
    </div>
    <div class="right">
      <div class="name">
        {{ user.displayName }}
      </div>
      Last login: {{ user.lastAccess ? displayTimeAgo(user.lastAccess) : 'New' }} <br />
      Position: {{ userType }}
    </div>
    <div class="super-right">
      <a-badge count="Responder" v-if="user.isResponder"></a-badge>
    </div>
  </div>
</template>

<script>
import PresenceThumbnail from "../../../../components/Presences/PresenceThumbnail.vue";
import Dates from "../../../../mixins/Dates";
import userHelpers from "../../../../helpers/users";
export default {
  props: ["user", "tenantId", "org"],
  mixins: [Dates],
  components: {PresenceThumbnail},
  computed: {
    userType() {
      return userHelpers.getUserPermissionDescription(this.user);
    }
  }
};
</script>

<style scoped lang="scss">
.user-list-item {
  background: #fff;
  padding: 20px;
  border-radius: 6px;
  cursor: pointer;
  border: 1px solid #fff;

  display: flex;

  .left {
    flex-shrink: 1;
    display: flex;
    align-items: center;

    min-width: 45px;
    text-align: center;
  }

  .right {
    padding-left: 15px;
    flex-grow: 1;
  }

  .super-right {
    padding-left: 10px;
    flex-shrink: 1;
    display: flex;
    align-items: center;
  }

  &:hover {
    color: #5155ea;
    border: 1px solid #5155ea;
  }

  .name {
    font-weight: 500;
  }
}
</style>