<template>
  <div class="admin-manage-users">
    <div class="page-header">
      <h1 class="page-title">
        <a-button type="default" style="display: inline; margin-right: 15px" @click.prevent="$router.push('/admin/users')"
          icon="left"></a-button>

        <admin-org-indicator></admin-org-indicator>

        Admin - Manage Users
      </h1>
      <div class="actions">
        <!-- Search -->
        <a-input-search v-if="!selectingMany" size="large" placeholder="Search users" v-model="searchQuery" />
        <!-- / Search -->

        <a-button v-if="!selectingMany" @click.prevent="startSelectMany" :disabled="isLoading"
          class="button-margin-left btn-rounded" icon="unordered-list" size="large" type="default">Select
          Multiple</a-button>

        <a-button v-if="!selectingMany" @click.prevent="loadUsers" :disabled="isLoading"
          class="button-margin-left btn-rounded" icon="reload" size="large" type="default">Refresh</a-button>

        <a-button v-if="!selectingMany" @click.prevent="emailInvites" class="button-margin-left btn-rounded" icon="mail"
          size="large" type="primary">Email Invites</a-button>

        <a-button v-if="!selectingMany" @click.prevent="createUser" class="button-margin-left btn-rounded" icon="plus"
          size="large" type="primary">Create</a-button>

        <!-- Selecting many -->
        <a-button v-if="selectingMany" @click.prevent="stopSelectMany" :disabled="isLoading"
          class="button-margin-left btn-rounded" icon="stop" size="large" type="default">Cancel</a-button>

        <a-button v-if="selectingMany" @click.prevent="attemptDeleteMany" :disabled="isLoading || !selectedIds.length"
          class="button-margin-left btn-rounded" type="danger" size="large">Delete</a-button>

        <a-button v-if="selectingMany" @click.prevent="attemptBlockMany" :disabled="isLoading || !selectedIds.length"
          class="button-margin-left btn-rounded" type="danger" size="large">Block</a-button>

        <a-button v-if="selectingMany" @click.prevent="attemptUnblockMany" :disabled="isLoading || !selectedIds.length"
          class="button-margin-left btn-rounded" type="danger" size="large">Unblock</a-button>
        <!-- / Selecting many -->
      </div>
    </div>

    <!-- Is Loading -->
    <div v-if="isLoading" class="loader">
      <a-spin></a-spin>
    </div>
    <!-- / Is Loading -->

    <!-- No users to show -->
    <a-alert v-if="!isLoading && usersToShow.length === 0" type="info" message="No users to show" />
    <!-- / No users to show -->

    <!-- Users list -->
    <div class="users-list" v-if="!selectingMany">
      <a-row type="flex" :gutter="20">
        <a-col :span="colSpan" v-for="user in usersToShow" :key="user.id">
          <user-list-item :tenant-id="tenantId" :user="user" @selected="() => navigateToUser(user)"
            :org="selectedOrganisation"></user-list-item>
        </a-col>
      </a-row>
    </div>
    <!-- / Users list -->

    <!-- Selecting Many -->
    <loading-screen :is-loading="isDeleting || isBlocking || isUnblocking"></loading-screen>
    <div class="selecting-many-list" v-if="selectingMany">
      <a-row type="flex" :gutter="20">
        <a-col :span="colSpan" v-for="user in usersToShow" :key="user.id">
          <selectable-user-list-item :tenant-id="tenantId" :user="user" @selected="() => toggleUserSelected(user)"
            :selected-user-ids="selectedIds" :org="selectedOrganisation"></selectable-user-list-item>
        </a-col>
      </a-row>
    </div>
    <!-- / Selecting Many -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
const _ = require("lodash");
import organisations from "../../../api/organisations";
import UserListItem from "./Manage/UserListItem.vue";
import SelectableUserListItem from "./Manage/SelectableUserListItem.vue";
import LoadingScreen from "../../../components/LoadingScreen.vue";
import presences from "../../../api/presences";
import axios from 'axios';
export default {
  data() {
    return {
      windowWidth: window.innerWidth,
      searchQuery: "",

      selectingMany: false,
      selectedIds: [],
      isDeleting: false,
      isBlocking: false,
      isUnblocking: false,

      isLoading: false,
      users: [],
    };
  },

  components: { UserListItem, SelectableUserListItem, LoadingScreen },

  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },

  created() {
    if (!this.selectedOrganisation) {
      this.$message.info("Please select an organisation");
      this.$router.push("/admin");
    }
    this.loadUsers();
  },

  watch: {
    selectedOrganisation() {
      this.loadUsers();
    },
  },

  computed: {
    ...mapGetters("admin", {
      selectedOrganisation: "selectedOrganisation",
      tenantId: "tenantId",
    }),

    colSpan() {
      // return this.windowWidth < 1200 ? 24 : 12;
      return 24;
    },

    usersToShow() {
      let vm = this;
      let filtered = _.filter(this.users, (user) => {
        if (vm.searchQuery.trim().length) {
          return user.displayName
            .toLowerCase()
            .includes(vm.searchQuery.trim().toLowerCase());
        } else {
          return true;
        }
      });
      return _.orderBy(filtered, "displayName");
    },
  },

  methods: {
    startSelectMany() {
      this.selectedIds = [];
      this.selectingMany = true;
    },

    stopSelectMany() {
      this.selectingMany = false;
    },

    attemptDeleteMany() {
      let vm = this;
      this.$confirm({
        title: "Are you sure you want to delete the selected users?",
        content:
          "The selected users will be able to log into any other organisations they have access to.",
        okText: "Delete",
        okType: "danger",
        cancelText: "Cancel",
        onOk() {
          vm.deleteMany();
        },
        onCancel() { },
      });
    },

    deleteMany() {
      let vm = this;
      vm.isDeleting = true;

      let requests = [];
      _.each(this.selectedIds, selectedId => {
        requests.push(
          presences.deletePresence(vm.tenantId, selectedId)
        )
      });

      axios.all(requests).then(() => {
        vm.isDeleting = false;
        vm.$message.success('Users deleted successfully');
        vm.selectingMany = false;
        vm.loadUsers();
      }).catch(e => {
        vm.isDeleting = false;
        console.log(e);
        vm.$message.error('Error deleting users');
      })
    },

    attemptBlockMany() {
      let vm = this;
      this.$confirm({
        title: "Are you sure you want to block the selected users?",
        okText: "Block",
        okType: "danger",
        cancelText: "Cancel",
        onOk() {
          vm.blockMany();
        },
        onCancel() { },
      });
    },

    blockMany() {
      let vm = this;
      vm.isBlocking = true;

      let requests = [];
      _.each(this.selectedIds, selectedId => {
        let presence = _.find(this.users, { id: selectedId });
        if (presence) {
          requests.push(
            presences.updatePresence(this.tenantId, {
              ...presence,
              permissions: 0
            })
          );
        }
      });

      axios.all(requests).then(() => {
        vm.isBlocking = false;
        vm.$message.success('Users blocked successfully');
        vm.selectingMany = false;
        vm.loadUsers();
      }).catch(e => {
        vm.isBlocking = false;
        console.log(e);
        vm.$message.error('Error blocking users');
      });
    },

    attemptUnblockMany() {
      let vm = this;
      this.$confirm({
        title: "Are you sure you want to unblock the selected users?",
        okText: "Unblock",
        okType: "danger",
        cancelText: "Cancel",
        onOk() {
          vm.unblockMany();
        },
        onCancel() { },
      });
    },

    unblockMany() {
      let vm = this;
      vm.isUnblocking = true;

      let requests = [];
      _.each(this.selectedIds, selectedId => {
        let presence = _.find(this.users, { id: selectedId });
        if (presence) {
          requests.push(
            presences.updatePresence(this.tenantId, {
              ...presence,
              permissions: (1 | 2)
            })
          );
        }
      });

      axios.all(requests).then(() => {
        vm.isUnblocking = false;
        vm.$message.success('Users unblocked successfully');
        vm.selectingMany = false;
        vm.loadUsers();
      }).catch(e => {
        vm.isUnblocking = false;
        console.log(e);
        vm.$message.error('Error unblocking users');
      });
    },

    toggleUserSelected(user) {
      this.selectedIds = _.xor(this.selectedIds, [user.id]);
    },

    navigateToUser(user) {
      this.$router.push("/admin/users/manage/" + user.id);
    },

    emailInvites() {
      this.$router.push("/admin/users/manage/email");
    },

    createUser() {
      this.$router.push("/admin/users/manage/create");
    },

    async loadUsers() {
      let vm = this;

      vm.isLoading = true;
      vm.users = [];

      try {
        vm.users = await organisations
          .getMembershipForOrg(vm.tenantId, vm.selectedOrganisation.id)
          .then((r) => r.data);
      } catch (e) {
        console.log(e);
        vm.$message.error("Error loading users");
      }

      vm.isLoading = false;
    },
  },
};
</script>

<style scoped lang="scss">
.user-list-item {
  margin-bottom: 15px;
}

.selectable-user-list-item {
  margin-bottom: 15px;
}

.loader {
  padding: 40px;
  text-align: center;
}

.button-margin-left {
  margin-left: 15px;
}
</style>