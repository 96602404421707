<template>
    <div class="selectable-user-list-item" @click.prevent="$emit('selected', user)">
        <div class="left">
            <a-checkbox :checked="isSelected">
            </a-checkbox>
            <!-- <presence-thumbnail :presence-id="user.id" :owner-id="org.id" size="large"></presence-thumbnail> -->
        </div>
        <div class="right">
            <div class="name">
                {{ user.displayName }}
            </div>
            Position: {{ userType }}
        </div>
        <div class="super-right">
            <a-badge count="Responder" v-if="user.isResponder"></a-badge>
        </div>
    </div>
</template>

<script>
// import PresenceThumbnail from "../../../../components/Presences/PresenceThumbnail.vue";
import Dates from "../../../../mixins/Dates";
import userHelpers from "../../../../helpers/users";
export default {
    props: ["user", "tenantId", "org", "selectedUserIds"],
    mixins: [Dates],
    components: { 
        // PresenceThumbnail 
    },
    computed: {
        userType() {
            return userHelpers.getUserPermissionDescription(this.user);
        },

        isSelected() {
            if (this.selectedUserIds) {
                return this.selectedUserIds.includes(this.user.id);
            }
            return false;
        }
    }
};
</script>

<style lang="scss">
.selectable-user-list-item {
    background: #fff;
    padding: 20px;
    border-radius: 6px;
    cursor: pointer;
    border: 1px solid #fff;

    display: flex;

    .left {
        flex-shrink: 1;
        display: flex;
        align-items: center;
        justify-items: center;
        justify-content: center;

        min-width: 45px;
        text-align: center;

        .ant-checkbox-inner {
            // width: 20px !important;
            // height: 20px !important;
        }
    }

    .right {
        padding-left: 15px;
        flex-grow: 1;
    }

    .super-right {
        padding-left: 10px;
        flex-shrink: 1;
        display: flex;
        align-items: center;
    }

    &:hover {
        color: #5155ea;
        border: 1px solid #5155ea;
    }

    .name {
        font-weight: 500;
    }
}
</style>